import { useLocation, Navigate, Outlet } from "react-router-dom"
import { useSelector } from "react-redux"
import { selectCurrentCollisionPass } from "../services/features/authenticationSlice"

const RequireAuth = () => {
    const collisionPass = useSelector(selectCurrentCollisionPass)
    const location = useLocation()
    return (
        collisionPass ? <Outlet /> : <Navigate to="/login" state={{ from: location}} replace/>
    )
}

export default RequireAuth