import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import store from './services/store'
import App from './App'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import reportWebVitals from './utils/reportWebVitals'
import serviceWorker from './utils/serviceWorker'

ReactDOM.render(
    <Provider store={store}>
        <BrowserRouter>
            <Routes>
                <Route path="/*" element={<App />} />
            </Routes>
        </BrowserRouter>
    </Provider>,
    document.getElementById('root')
)
reportWebVitals()
serviceWorker()