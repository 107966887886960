import React from 'react'
//Third-party
import { IconButton, Box, Tooltip } from '@mui/material/'
import { ExitToApp } from '@mui/icons-material'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
//Custom
import { logOut } from '../services/features/authenticationSlice'

export default function HeaderMenu() {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const logoutClick = _ => {
        dispatch(logOut())
        navigate('/', { replace: true })
    }

    const exitIcon = (
        <IconButton size="large" edge="end" aria-haspopup="true" aria-label="LogOut" onClick={logoutClick} color="inherit">
            <ExitToApp />
        </IconButton>
    )

    return (
        <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
            <Tooltip title={'Sair'}>{exitIcon}</Tooltip>
        </Box>
    )
}