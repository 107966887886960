import * as React from "react"
import { createTheme, ThemeProvider } from "@mui/material/styles"
import CssBaseline from "@mui/material/CssBaseline"
import Box from "@mui/material/Box"
import Toolbar from "@mui/material/Toolbar"
import Container from "@mui/material/Container"
import Grid from "@mui/material/Grid"
import Paper from "@mui/material/Paper"
import Footer from "../components/Footer"
import Header from "../components/Header"
import HomeView from '../views/HomeView'

const mdTheme = createTheme()

export default function App() {

    return (
        <ThemeProvider theme={mdTheme}>
            <Box sx={{ display: "flex" }}>
                <CssBaseline />
                <Header />
                <Box component="main" sx={{
                    backgroundColor: (theme) => theme.palette.mode === "light" ? theme.palette.grey[100] : theme.palette.grey[900],
                    flexGrow: 1, height: "100vh", overflow: "auto"
                }} >
                    <Toolbar />
                    <Container maxWidth="false" sx={{ mt: 4, mb: 4 }}>
                        <Grid container direction="row" justify="center" alignItems="center">
                            <Grid item xs={12} md={12} lg={12}>
                                <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
                                    <HomeView />                                    
                                </Paper>
                            </Grid>
                        </Grid>
                        <br />
                        <Footer sx={{ mt: 5 }} />
                    </Container>
                </Box>
            </Box>
        </ThemeProvider>
    )
}