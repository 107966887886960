import React from "react"
import Typography from '@mui/material/Typography'
import Link from '@mui/material/Link'
import logo from "../assets/logo.png"

export default function Copyright(props) {
    return (
        <Typography variant="body2" color="textSecondary" align="center" {...props}>
            <img src={logo} alt="Logo" width="350px" />
            <br />
            <br />
            {"Copyright © "}
            <Link color="inherit" href="https://www.buscasys.com.br/" target="blank">
                Softica
            </Link>{" "}
            {new Date().getFullYear()}
            {"."}
        </Typography>
    )
}