import React from 'react'
import { Routes, Route } from 'react-router-dom'
import Screen from './components/Screen'
import AuthenticationView from './views/AuthenticationView'
import RequireAuth from './components/RequireAuth'
import ScrollTop from './components/ScrollTop'
import Layout from './components/Layout'
import { createTheme, ThemeProvider } from "@mui/material/styles"
import Messanger from "./components/Messanger"
import NotFoundView from './views/NotFoundView'
const theme = createTheme()

function App() {
    return (
        <ThemeProvider theme={theme}>
            <ScrollTop>
                <Routes>
                    <Route path="/" element={<Screen />}>
                        <Route path="/login" element={<AuthenticationView/>} />
                        <Route element={<RequireAuth />}>
                            <Route path="/" element={<Layout />}>                                
                            </Route>
                        </Route>
                        <Route path="*" element={<NotFoundView />} />
                    </Route>
                </Routes>
                <Messanger />
            </ScrollTop>
        </ThemeProvider>
    )
}

export default App