import { baseApi } from '../baseApi'

export const modelApi = baseApi.injectEndpoints({
    endpoints: builder => ({
        getSimilariry: builder.mutation({
            query: data => ({
                url: '/get_similarity',
                method: 'POST',
                body: data
            }),
        }),       
    })
})

export const { useGetSimilariryMutation } = modelApi