import React from 'react'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import AppBar from '@mui/material/AppBar'
import HeaderMenu from './HeaderMenu'
import CompareIcon from '@mui/icons-material/Compare'

export default function Header(props) {
    return (
        <AppBar position="absolute" open={false} style={{backgroundColor: "#041E42"}}>
            <Toolbar sx={{ pr: '24px' }}>
                <CompareIcon />
                <Typography component="h1" variant="h6" color="inherit" noWrap sx={{ paddingLeft: 2, flexGrow: 1, fontWeight: 600, fontFamily: 'Verdana' }}>Softica - Collision of Images</Typography>
                <HeaderMenu utils={props.utils}/>
            </Toolbar>
        </AppBar>
    )
}
