import { configureStore } from '@reduxjs/toolkit'
import { reducer as toastrReducer } from 'react-redux-toastr'
import { baseApi } from "./baseApi"
import { rtkQueryErrorLogger } from './errorHandling'
import authReducer from './features/authenticationSlice'

export default configureStore({
  reducer: {
    toastr: toastrReducer,
    [baseApi.reducerPath]: baseApi.reducer,
    authentication: authReducer,
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware().concat(baseApi.middleware).concat(rtkQueryErrorLogger),
  devTools: false
})