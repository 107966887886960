import React, { useState } from 'react'
import { Box, Paper, Grid, Typography, Button } from '@mui/material'
import Content from '../components/Content'
import ContentHeader from '../components/ContentHeader'
import { useGetSimilariryMutation } from '../services/api/modelApi'

export default function HomeView() {

    const [image1, setImage1] = useState(null)
    const [image2, setImage2] = useState(null)
    const [similarity, setSimilarity] = useState(null)

    const [getSimilariry, { isLoading }] = useGetSimilariryMutation()

    const handleImage1Upload = (event) => {
        const file = event.target.files[0]
        setImage1(file)
    }

    const handleImage2Upload = (event) => {
        const file = event.target.files[0]
        setImage2(file);
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        if (image1 && image2) {
            const formData = new FormData()
            formData.append('image1', image1, image1.name)
            formData.append('image2', image2, image2.name)
            getSimilariry(formData).unwrap().then((response) => {
                console.log(response.similarity_percentage)
                setSimilarity(response.similarity_percentage)
            }).catch((error) => {
                console.log(error)
            })
        }
    }

    return (
        <div>
            <ContentHeader title={'Colisão de Imagens'} />
            <Content>
                <Paper elevation={3} sx={{ marginTop: 3, maxHeight: 600, overflowY: "hidden" }}>
                    <Box sx={{ flexGrow: 1, p: 2 }}>
                        <form onSubmit={handleSubmit}>
                            <Grid container spacing={2}>
                                <Grid item xs={2}>
                                    {image1 && (
                                        <img src={URL.createObjectURL(image1)} alt="imageForCollision 1" width="200" height="200" />
                                    )}
                                    <br/>
                                    <Button style={{ backgroundColor: '#363435' }} component="label" variant="contained" sx={{ mt: 3, mb: 2 }}>
                                        Carregar imagem 1
                                        <input hidden type="file" accept="image/*" onChange={handleImage1Upload} />
                                    </Button>
                                </Grid>
                                <Grid item xs={2}>
                                    {image2 && (
                                        <img src={URL.createObjectURL(image2)} alt="imageForCollision 2" width="200" height="200" />
                                    )}
                                    <br/>                                    
                                    <Button style={{ backgroundColor: '#363435' }} component="label" variant="contained" sx={{ mt: 3, mb: 2 }}>
                                        Carregar imagem 2
                                        <input hidden type="file" accept="image/*" onChange={handleImage2Upload} />
                                    </Button>
                                </Grid>
                                <Grid item xs={2}>
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    <Button style={{ backgroundColor: '#339a99' }} type="submit" variant="contained" disabled={isLoading} sx={{ mt: 3, mb: 2 }}>
                                        {isLoading ? 'Loading...' : 'Calcular similarirdade'}
                                    </Button>
                                </Grid>
                                <Grid item xs={6}>
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    <Typography sx={{ mt: 1 }} variant="h2" gutterBottom>Similaridade: {similarity || '?'}%</Typography>
                                </Grid>
                            </Grid>
                        </form>
                    </Box>
                </Paper>
            </Content>
        </div >
    )
}