import React from 'react'
import { Box, Button, Container, Typography } from '@mui/material'
import Grid from '@mui/material/Grid'
import { useNavigate } from 'react-router-dom'

export default function NotFoundView(props) {
    const navigate = useNavigate()
    const goHome = _ =>{
        navigate('/', { replace: true })
    }

    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                minHeight: '95vh'
            }}
        >
            <Container maxWidth="md">
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <Typography variant="h1">
                            404
                        </Typography>
                        <Typography variant="h6">
                           Not Found
                        </Typography>
                        <Button variant="contained" style={{ backgroundColor: '#ec008c' }} onClick={goHome}>Voltar para o início</Button>
                    </Grid>
                    <Grid item xs={6}>
                        <img src="https://cdn.pixabay.com/photo/2017/03/09/12/31/error-2129569__340.jpg" alt="404" width={500} height={250} />
                    </Grid>
                </Grid>
            </Container>
        </Box>
    )
}