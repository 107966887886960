import React from 'react';
import Avatar from '@mui/material/Avatar'
import Button from '@mui/material/Button'
import CssBaseline from '@mui/material/CssBaseline'
import TextField from '@mui/material/TextField'
import Paper from '@mui/material/Paper'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import Typography from '@mui/material/Typography'
import Backdrop from '@mui/material/Backdrop'
import CircularProgress from '@mui/material/CircularProgress'
import { useNavigate } from 'react-router-dom'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { useForm, Controller } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { setCredentials } from '../services/features/authenticationSlice'
import Footer from '../components/Footer'
import loginBackground from '../assets/bg.jpg'
import { useValidatePasswordMutation } from '../services/api/authenticationApi'

const theme = createTheme()

export default function AuthenticationView() {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { control, handleSubmit } = useForm()
    const [validatePassword, { isLoading }] = useValidatePasswordMutation()

    const onSubmit = async data => {
        try {
            await validatePassword(data.password).unwrap()
            dispatch(setCredentials({ password: data.password }))
            navigate('/', { replace: true })
        } catch (ignore) {

        }
    }

    return (
        <ThemeProvider theme={theme}>
            <Grid container component="main" sx={{ height: '100vh' }}>
                <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading}>
                    <CircularProgress color="inherit" />
                </Backdrop>
                <CssBaseline />
                <Grid item xs={false} sm={4} md={7} sx={{
                    backgroundImage: `url(${loginBackground})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundColor: (t) => t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                }}
                />
                <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
                    <Box sx={{ my: 8, mx: 4, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <Avatar sx={{ m: 1 }} style={{ backgroundColor: '#339a99' }}>
                            <LockOutlinedIcon />
                        </Avatar>
                        <Typography component="h1" variant="h5">Autenticação</Typography>
                        <Box component="form" onSubmit={handleSubmit(onSubmit)} sx={{ mt: 1 }}>
                            <Controller control={control} name="password" defaultValue="" rules={{ required: true }} render={({ field, fieldState: { error } }) => (
                                <TextField {...field} InputLabelProps={{ required: true }} error={Boolean(error)} margin="normal" fullWidth label={'Senha'}
                                    type="password" helperText={error ? 'Senha é obrigatória' : ''} autoComplete="password" />
                            )} />
                            <Button style={{ backgroundColor: '#339a99' }} type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>Entrar</Button>
                            <Box mt={5}>
                                <Footer />
                            </Box>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </ThemeProvider>
    )
}