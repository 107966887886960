import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { baseApiUrl } from '../utils/constants'
import { logOut } from './features/authenticationSlice'

const baseQuery = fetchBaseQuery({
    baseUrl: baseApiUrl,
    prepareHeaders: (headers, { getState, endpoint }) => {
        const collisionPass = getState().authentication.collisionPass
        if (collisionPass) {
            headers.set('Authorization', collisionPass)
        }      
        return headers
    }
})

const baseQueryWithReauth = async (args, api, extraOptions) => {
    let result = await baseQuery(args, api, extraOptions)
    if (result?.error?.status === 401) {        
        api.dispatch(logOut())
    }

    return result
}

export const baseApi = createApi({
    baseQuery: baseQueryWithReauth,
    endpoints: builder => ({})
})