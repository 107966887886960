import { baseApi } from '../baseApi'

export const authenticationApi = baseApi.injectEndpoints({
    endpoints: builder => ({
        validatePassword: builder.mutation({
            query: data => ({
                url: '/validate_password',
                method: 'POST',
                body: { password: data }
            }),
        }), 
    })
})

export const { 
    useValidatePasswordMutation,
} = authenticationApi 