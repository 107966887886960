import { createSlice } from '@reduxjs/toolkit'
import { userKey } from '../../utils/constants'

const initialState  = {
  collisionPass: JSON.parse(localStorage.getItem(userKey))?.collisionPass
}

export const authenticationSlice = createSlice({
  name: 'authenticationSlice',
  initialState,
  reducers: {
    setCredentials: (state, action) => {
        localStorage.setItem(userKey, JSON.stringify({collisionPass: action.payload.password }))
        state.collisionPass = action.payload.password
    },
    logOut: (state, action) => {
        state.collisionPass = null
    }
  },
})

export const { setCredentials, logOut } = authenticationSlice.actions
export const selectCurrentCollisionPass = (state) => state.authentication.collisionPass
export default authenticationSlice.reducer