import { isRejectedWithValue } from '@reduxjs/toolkit'
import { toastr } from 'react-redux-toastr'

export const rtkQueryErrorLogger = (api) => (next) => (action) => {
  if (isRejectedWithValue(action)) {
    console.log('Payload: ', action.payload)
    let errorString = 'Error'
    if (action.payload.error) {
      errorString = action.payload.error
    } else if (action.payload.data) {
      errorString = action.payload.data.title
    }
    toastr.error(action.payload.status, errorString)
  }

  return next(action)
}